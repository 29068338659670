<template>
	<div class="authPage">
		<div
			class="
				content-top-bar
				d-flex
				justify-content-between
				align-items-center
				bg-white
			"
			:class="{
				'top-bar-fixed p-3': helpers.layout.isMobile(),
				'position-sticky p-4': !helpers.layout.isMobile(),
			}"
		>
			<router-link :to="{ name: 'auth.login' }" class="text-dark d-flex">
				<span class="material-icons-outlined" aria-label="back">
					arrow_back
				</span>
			</router-link>
		</div>

		<div
			:class="{
				'pb-104': helpers.layout.isMobile(),
				'height-minus-top-bar': !helpers.layout.isMobile(),
			}"
		>
			<div
				class="mx-auto"
				:class="{
					'w-100': helpers.layout.isMobile(),
					'w-50 pt-40': !helpers.layout.isMobile(),
				}"
			>
				<div
					:class="{
						'pt-4 px-3': helpers.layout.isMobile(),
					}"
				>
					<div class="text-center header">
						<h1 class="mb-0">Set New Password</h1>
					</div>
				</div>
			</div>

			<kr-form
				ref="form"
				v-slot="{ meta, rules, data, isSubmitting }"
				:options="formOptions"
				class="needs-validation"
				autocomplete="false"
				novalidate
			>
				<div
					class="mx-auto"
					:class="{
						'w-100 px-3': helpers.layout.isMobile(),
						'w-50 min-h-100': !helpers.layout.isMobile(),
					}"
				>
					<kr-change-password
						:data="data"
						:rules="rules"
						@canSend="processCanSendChangePassword"
					></kr-change-password>
				</div>
				<div
					:class="{
						'position-fixed bottom-0 start-0 w-100': helpers.layout.isMobile(),
						'position-sticky bottom-0 pt-30': !helpers.layout.isMobile(),
					}"
				>
					<div
						v-if="helpers.layout.isMobile()"
						class="custom-btn-gradient bg-gradient"
					/>
					<div
						v-if="generalFormErrors"
						class="alert alert-danger text-white d-flex align-items-center"
						role="alert"
						aria-live="assertive"
						aria-atomic="true"
					>
						<span class="material-icons-outlined" aria-hidden="true">
							error_outline
						</span>
						{{ generalFormErrors }}
					</div>
					<button
						type="submit"
						class="w-100 btn btn-primary"
						:disabled="
							!meta.valid ||
							!meta.dirty ||
							!canSendChangePassword ||
							isSubmitting
						"
						@click="send"
					>
						<template v-if="isSubmitting">
							<span
								class="spinner-border spinner-border-sm me-8px"
								role="status"
								aria-hidden="true"
							></span>
							Loading...
						</template>
						<template v-else> Reset Password </template>
					</button>
				</div>
			</kr-form>
		</div>
	</div>
</template>
<script>
import { ref, inject } from "vue";
import { useRouter, useRoute } from "vue-router";
import KrChangePassword from "../../../components/forms/components/kr-change-password.vue";

export default {
	components: {
		KrChangePassword,
	},
	setup() {
		const $api = inject("$api");
		const helpers = inject("helpers");
		const _ = inject("_");
		const router = useRouter();
		const route = useRoute();
		const apiUrl = route.query.api_url;
		const form = ref(null);
		const generalFormErrors = ref(null);
		const formOptions = ref({
			fields: {
				token: { default: route.params.token, rules: "required" },
				email: { default: route.query.email, rules: "required" },
				password: { value: "", rules: "password|required" },
				password_confirmation: {
					value: "",
					rules: "password_confirmation|required",
				},
				means: { default: "token" },
			},
		});
		let canSendChangePassword = ref(false);
		const processCanSendChangePassword = (bool) => {
			canSendChangePassword.value = bool;
		};
		const send = () => {
			generalFormErrors.value = "";
			form.value.handleSubmit(() => {
				return $api.auth.password
					.reset(form.value.values, apiUrl)
					.then(function (response) {
						router.push({ name: "auth.login.email", query: { from: "reset" } });
					})
					.catch(function (errors) {
						if (_.get(errors, "response.data.errors.password", false)) {
							form.value.setErrors(
								_.get(
									errors,
									"response.data.errors",
									"Something went wrong,please try again later"
								)
							);
						} else {
							if (_.get(errors, "response.data.errors.token", false)) {
								generalFormErrors.value = errors.response.data.errors.token[0];
							} else {
								generalFormErrors.value =
									"Something went wrong, please try again later.";
							}
						}
					});
			})();
		};
		return {
			helpers,
			generalFormErrors,
			form,
			formOptions,
			canSendChangePassword,
			processCanSendChangePassword,
			send,
		};
	},
};
</script>
<style lang="scss" scoped>
.contentBody {
	height: calc(100% - 134px - 116px);
}
</style>
